import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import { useSelector } from "react-redux";

const AddNewUsers = ({ show, setShow, setFormData, formData, passwordError, onSubmit, action }) => {
    const [notification, setNotification] = useState({ show: false, title: "", body: "", button: "" });
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [accounts, setAccounts] = useState({ iaai: { buyer: [] }, copart: [] });
    const admin = useSelector((state) => state.user);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://countriesnow.space/api/v0.1/countries/positions'
        };

        axios(config)
            .then((response) => {
                const data = response.data.data;
                setCountries([...data])
            })
            .catch((err) => {
                setNotification({ show: true, title: "Error", body: "Countries not loaded", button: "Close" })
            })

        if (admin.role === "sadmin") {
            axios('/api/admins?limit=100&page=1')
                .then((response) => {
                    const data = response.data.data;
                    console.log(data)
                    setAdmins(data);
                })
                .catch((err) => {
                    setNotification({ show: true, title: "Error", body: "Admins not loaded", button: "Close" })
                })

            setFormData((prev) => ({ ...prev, adminName: admin.username }));
        }

        axios('/api/accounts?limit=100&page=1')
            .then((response) => {
                const data = response.data.data;
                const copart = data.filter((a) => a.auction === "copart");
                let iaai = data.filter((a) => a.auction === "iaai");
                iaai = iaai.map((i) => ({ label: i.buyer, value: i.buyer }));
                setAccounts({ copart: [...copart], iaai: iaai });
            })
            .catch((err) => {
                setNotification({ show: true, title: "Error", body: "Admins not loaded", button: "Close" })
            })
    }, [])

    return (
        <>
            <Modal show={show} onHide={setShow} size={"lg"} centered>
                <Modal.Header><h4>{action == "edit" ? "Edit user" : "Add new user"}</h4></Modal.Header>
                <Modal.Body>
                    <form className={"add-new-users-form"} onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit()
                    }}>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Name</label>
                                    <input required type={"text"} value={formData.name} className={"form-control"}
                                        placeholder={"Enter name"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, name: e.target.value }))
                                        }} />
                                </div>
                                <div className={"form-group"}>
                                    <label>Surname</label>
                                    <input required type={"text"} value={formData.surname} className={"form-control"}
                                        placeholder={"Enter surname"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, surname: e.target.value }))
                                        }} />
                                </div>
                                {/* <div className={"form-group"}>
                                    <label>Country</label>
                                    <select required value={formData.country} className={"form-control"}
                                        onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, country: e.target.value }));
                                            const urlencoded = new URLSearchParams();
                                            urlencoded.append("country", e.target.value);
                                            let config = {
                                                method: 'post',
                                                maxBodyLength: Infinity,
                                                url: 'https://countriesnow.space/api/v0.1/countries/cities',
                                                headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                },
                                                data: urlencoded
                                            };

                                            const phones = {
                                                method: 'post',
                                                maxBodyLength: Infinity,
                                                url: 'https://countriesnow.space/api/v0.1/countries/codes',
                                                headers: {
                                                    'Content-Type': 'application/x-www-form-urlencoded'
                                                },
                                                data: urlencoded
                                            };

                                            axios(phones)
                                                .then((response) => {
                                                    const data = response.data.data;
                                                    setFormData((prev) => ({ ...prev, phone: `${data.dial_code} ` }));
                                                })
                                                .catch((err) => {
                                                    setNotification({
                                                        show: true,
                                                        title: "Error",
                                                        body: "Dial code not received",
                                                        button: "Close"
                                                    })
                                                })

                                            axios(config)
                                                .then((response) => {
                                                    const data = response.data.data;
                                                    setCities([...data]);
                                                })
                                                .catch((err) => {
                                                    setNotification({
                                                        show: true,
                                                        title: "Error",
                                                        body: "Cities not loaded",
                                                        button: "Close"
                                                    })
                                                })
                                        }}>
                                        <option>Select country</option>
                                        {
                                            countries.map((c) => (
                                                <option value={c.name} key={c.iso2}>{c.name}</option>))
                                        }
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>City</label>
                                    <select required value={formData.city} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, city: e.target.value }));
                                    }}>
                                        <option>Select City</option>
                                        {
                                            cities.map((c) => (<option value={c} key={c}>{c}</option>))
                                        }
                                    </select>
                                </div> */}
                                <div className={"form-group"}>
                                    <label>Username</label>
                                    <input type={"text"} required value={formData.username} className={"form-control"}
                                        placeholder={"Enter username"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, username: e.target.value }))
                                        }} />
                                </div>
                                <div className={"form-group"}>
                                    <label>Password</label>
                                    <input type={"password"} required={action !== "edit"} value={formData.password}
                                        className={"form-control"}
                                        placeholder={"Enter password"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, password: e.target.value }))
                                        }} />
                                    {passwordError && <span className={"authorization-error"}>Please insert more then 6 symbol</span>}
                                </div>
                                <div className={"form-group"}>
                                    <label>Email</label>
                                    <input type={"email"} required value={formData.email} className={"form-control"}
                                        placeholder={"Enter email"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, email: e.target.value }))
                                        }} />
                                </div>
                            </div>
                            <div className={"col-md-6"}>
                                <div className={"form-group"}>
                                    <label>Phone</label>
                                    <input type={"text"} required value={`${formData.phone}`} className={"form-control"}
                                        placeholder={"Enter phone"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, phone: `${e.target.value}` }))
                                        }} />
                                </div>
                                {admin.role === "sadmin" && <div className={"form-group"}>
                                    <label>Admin</label>
                                    <select value={formData.adminName} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, adminName: e.target.value }));
                                    }}>
                                        <option>Select Admin</option>
                                        {
                                            admins.map((c) => (
                                                <option value={c.username} key={c.id}>{c.username}</option>))
                                        }
                                    </select>
                                </div>}
                                <div className={"form-group"}>
                                    <label>Copart account</label>
                                    <select value={formData.copartAccount} className={"form-control"} onChange={(e) => {
                                        setFormData((prev) => ({ ...prev, copartAccount: e.target.value }));
                                    }}>
                                        <option>Select Account</option>
                                        {
                                            accounts.copart.map((c) => (
                                                <option value={c.buyer} key={c.buyer}>{c.buyer}</option>))
                                        }
                                    </select>
                                </div>
                                <div className={"form-group"}>
                                    <label>IAAI account</label>
                                    <Select
                                        isMulti
                                        className={"form-control no-padding"}
                                        options={accounts.iaai}
                                        value={formData.iaaiAccount}
                                        onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, iaaiAccount: [...e] }))
                                        }}
                                    />
                                </div>
                                {formData.hasLimit && <div className={"form-group"}>
                                    <label>Bid limit</label>
                                    <input type={"text"} required value={`${formData.bidLimit}`} className={"form-control"}
                                        placeholder={"Enter bid limit"} onChange={(e) => {
                                            setFormData((prev) => ({ ...prev, bidLimit: e.target.value }))
                                        }} />
                                </div>}
                                <div className={"form-group"}>
                                    <label>Controls</label>
                                    <div className={'form-checkboxes'}>
                                        <div className={'form-check'}>
                                            <input id={"copart-status"} type={"checkbox"} onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, copartStatus: e.target.checked }))
                                            }} className={"form-check-input"} defaultChecked={[formData].some(i => i.copartStatus === true)} />
                                            <label htmlFor={"copart-status"}
                                                className={"form-check-label"}>Copart</label>
                                        </div>
                                        <div className={'form-check'}>
                                            <input id={"iaai-status"} defaultChecked={[formData].some(i => i.iaaiStatus === true)} onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, iaaiStatus: e.target.checked }))
                                            }} type={"checkbox"} className={"form-check-input"} />
                                            <label htmlFor={"iaai-status"} className={"form-check-label"}>IAAI</label>
                                        </div>
                                        <div className={'form-check'}>
                                            <input id={"iaai-status"} defaultChecked={[formData].some(i => i.hasLimit === true)} onChange={(e) => {
                                                setFormData((prev) => ({ ...prev, hasLimit: e.target.checked }))
                                            }} type={"checkbox"} className={"form-check-input"} />
                                            <label htmlFor={"iaai-status"} className={"form-check-label"}>Set bid limit</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-12"} style={{ marginTop: "25px" }}>
                            <button className={"blue-button"} style={{ width: "100%" }} type={"submit"}>{action === "edit" ? 'Save' : 'Create User'}</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}>
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Footer>
                    <button className={"blue-button"} onClick={() => setNotification((prev) => ({
                        ...prev,
                        show: false
                    }))}>{notification.button}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddNewUsers